<div class="container-fluid">
  <div class="row vh-100 align-content-center" *ngIf="loading">
    <div class="col text-center">
      <i class="fa-light fa-spinner spinning ms-2 my-fs-5"></i>
    </div>
  </div>

  <ng-container *ngIf="!loading">
    <ng-container *ngIf="authService.userBundle === null">
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
          <h4 class="text-info text-center">{{ data.formationName | ms }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
          <h4 class="text-info text-center">{{ data.formationSessionName }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
          <h4 class="text-info text-center">
            {{
              'global.Du \{\{startDate\}\} au \{\{endDate\}\}'
                | translate
                  : {
                      startDate: data.minDate | localizedDate,
                      endDate: data.maxDate | localizedDate,
                    }
            }}
          </h4>
        </div>
      </div>

      <div class="row">
        <div class="col"><hr /></div>
      </div>
    </ng-container>

    <div class="row">
      <div class="col">
        <h4 class="text-info text-center">{{ 'mif.satisfaction.answer.Ma satisfaction' | translate }}</h4>
      </div>
    </div>

    <div class="row align-center mt-3" *ngIf="view">
      <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
        <div class="container-fluid bg-white rounded-3 border pb-3">
          <div class="row justify-content-end">
            <div class="col">
              <hiji-tag-radar-chart [data]="radarData"></hiji-tag-radar-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="data.oogs.length === 0">
      <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3">
        <ul class="errors">
          <li>
            {{'global.Erreur de configuration, veuillez contacter votre administrateur en précisant le code suivant : {{code}}' | translate:{ code:
            'no_repository_for_session_'+idFormationSession} }}
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3" *ngFor="let og of data.oogs | orderBy: 'order'; let first = first">
        <hiji-observable-gesture-evaluation
          [criteria]="getCriteria(og.idCriteria)"
          [observableGesture]="og"
          [observed]="og.observed"
          [text]="og.text"
          [value]="og.value"
          (observe)="observe($event, og)"
          (observeText)="observeText($event, og)"
          [first]="first"
          [autoobs]="'autoobs'"
          [disabled]="(dataParticipant !== null && dataParticipant.satisfactionDone) || view"
        ></hiji-observable-gesture-evaluation>
      </div>
    </div>

    <ng-container *ngIf="expectations !== null && expectations.expectationsParticipantEnabled">
      <div class="row mt-5">
        <div class="col">
          <h4 class="text-info text-center">{{ 'mif.satisfaction.answer.Ma satisfaction par rapport à mes attentes' | translate }}</h4>
        </div>
      </div>

      <div class="row">
        @for (expectation of getMyExpectations(); track $index) {
          <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3">
            <div class="bg-white rounded-3 border p-2">
              <hiji-observable-gesture-result
                [criteria]="expectations.criteriaExpectations"
                [answers]="computeAnswers(expectation)"
                [calculateDonutByNumberOfAnswerTypes]="true"
                [showAnswerDates]="true"
                [showSkill]="false"
                [showMickeyEars]="false"
                [observableGesture]="getExpectationAsOG(expectation)"
                (observe)="observeCriteria($event, expectation)"
                [evaluationMode]="!view"
              ></hiji-observable-gesture-result>
            </div>
          </div>
        } @empty {
          <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3">
            <div class="bg-white rounded-3 border p-3">
              {{ 'mif.expectation.results.Aucune attente définie par le participant' | translate }}
            </div>
          </div>
        }
      </div>
    </ng-container>

    <div class="row mt-3" *ngIf="!alreadyAnswer && !view">
      <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 pointer mt-3">
        <button class="btn btn-secondary btn-lg border btn-block w-100 text-white" (click)="save()" [disabled]="loadingSave">
          <span>{{ 'global.Valider' | translate }}</span>
          <i class="fa-light fa-check m-2" *ngIf="!loadingSave"></i>
          <i class="fa-light fa-spinner spinning ms-2" *ngIf="loadingSave"></i>
        </button>
      </div>
    </div>
  </ng-container>
</div>
